<template>
  <div class="contents">
    <audio
      id="bgmSound"
      :src="routePath + bgmSoundPath"
      loop="true"
    />
    <div class="vr-scene">
      <a-scene id="scene">
        <a-assets id="assets-area">
          <video
            v-for="(face, index) in faceList"
            :id="index"
            :key="index"
            :src="`${routePath}face/${face}`"
            preload="auto"
            playsinline
            autoplay
            muted
            loop
          />
        </a-assets>
        <RoomScene
          ref="room"
          :image-route-path="imageRoutePath"
          :glb-route-path="routePath + 'glb/'"
          :json-data="jsonData"
          @setGlbData="setGlbData"
          @loadFlag="loadFlag=true"
          @setConfirmWindow="setConfirmWindow"
        />
        <!-- アバター -->
        <a-entity id="avatar-parts">
          <a-entity
            v-for="(avatar, index) in avatarData"
            :id="index"
            :key="index"
            :position="avatar.position"
            :rotation="avatar.rotation"
            @click="index === 'info-avatar' ? setConfirmWindow(index) : null"
          >
            <a-entity
              :gltf-model="`${routePath}simple-avatar/${avatar.model}`"
              rotation="0 180 0"
            />
            <a-video
              v-if="avatar.type === 'video'"
              :src="`#${avatar.face}`"
              position="0 1.03 0.175"
              width="0.6"
              height="0.34"
            />
            <a-image
              v-else
              :src="`${routePath}face/${avatar.face}.jpg`"
              position="0 1.03 0.175"
              width="0.6"
              height="0.34"
            />
          </a-entity>
        </a-entity>
        <!-- 吹き出し -->
        <a-entity id="inquiry">
          <a-image
            id="bubble-speech"
            :src="`${routePath}images/speech-bubble.png`"
            position="-3.3 1.7 -4.414"
            rotation="0 180 0"
            width="1"
            height="0.4"
          />
          <a-image
            id="text-inquiry"
            :src="`${routePath}images/inquiry-text.png`"
            position="-3.3 1.7 -4.415"
            rotation="0 180 0"
            width="1"
            height="0.4"
          />
          <a-cone
            position="-3.315 1.36 -4.482"
            rotation="180 0 0"
            color="#FF4333"
            radius-bottom="0.05"
            radius-top="0"
            height="0.1"
            animation="property: object3D.position.y;to: 1.42;dir: alternate;dur: 1000;loop: true"
          />
        </a-entity>
      </a-scene>
    </div>
    <LoadingView v-if="!loadFlag" />
    <SoundPermission
      v-if="loadFlag && !soundPermissionFlag"
      @sound-permission="soundPermissionFlag=true"
    />
    <ConfirmWindow
      v-if="confirmWindowFlag"
      :target-url="targetUrl"
      @close-window="confirmWindowFlag=false"
    />
  </div>
</template>

<script>
import utilsMixin from '../mixins/utils'
import LoadingView from '../components/LoadingView'
import SoundPermission from '../components/SoundPermission'
import ConfirmWindow from '../components/ConfirmWindow'
import RoomScene from '../components/RoomScene'
import settingJson from '../assets/setting-tenup.json';

export default {
  name: 'TenupView',
  components: {
    RoomScene,
    LoadingView,
    SoundPermission,
    ConfirmWindow
  },
  mixins: [utilsMixin],
  props: {},
  data() {
    return {
      title: '株式会社テンアップ',    // ページタイトル
      description: 'メタバース空間内のオフィスをご案内します。', // ページ説明
      loadFlag: false,                          // ロード画面用フラグ
      soundPermissionFlag: false,                // BGM許可フラグ
      confirmWindowFlag:false,                  // 遷移先確認画面表示フラグ
      targetUrl: '',                            // 遷移先URL
      bgmSoundPath: 'sounds/bgm.mp3',           // オフィス内BGM
      jsonData: settingJson,                    // 初期設定の情報
      imageRoutePath: '/assets/tenup/images/',  // 画像ルートパス
      routePath: '/assets/tenup/',              // 顔データルートパス
      glbData: {},                              // 子Vueで取得したGLBデータ
      warpData: { // 採用案内
        warp1: {
          url: 'https://www.vr-lite.net/#/player/feQv5De7mvibLM1P/g6LUJKTO',
          position: { x: 4.773, y: 0, z: -6.239 }
        },
        warp2: {  // 事業案内
          url: 'https://www.vr-lite.net/#/player/xOklTbTIbQYznkZj/p9llOF9C',
          position: { x: -7.063, y: 0, z: -6.188 }
        },
        warp3: { // デモ空間
          position: { x: -1.169, y: 0, z: 11.72 }
        }
      },
      faceList: { // 顔動画
        wavehands: 'wavehands.mp4',
        reception: 'reception.mp4',
        talkingman: 'talkingman.mp4',
        talkingwoman: 'talkingwoman.mp4'
      },
      avatarData: { // アバターの情報
        'info-avatar': {  // お問合せリンク付き
          model: 'job-bus-guide.glb',
          type: 'video',
          face: 'reception',
          position: '-3.316 0 -4.476',
          rotation: '0 180 0'
        },
        avatar1: {
          model: 'office-woman.glb',
          type: 'video',
          face: 'wavehands',
          position: '2.5 0 -9.5',
          rotation: '0 180 0'
        },
        avatar2: {
          model: 'office-man.glb',
          type: 'image',
          face: 'man',
          position: '-8.6 0 -10.5',
          rotation: '0 120 0'
        },
        avatar3: {
          model: 'casual-woman.glb',
          type: 'video',
          face: 'talkingwoman',
          position: '0 0 2.2',
          rotation: '0 160 0'
        },
        avatar4: {
          model: 'casual-man.glb',
          type: 'video',
          face: 'talkingman',
          position: '0.9 0 2',
          rotation: '0 -130 0'
        },
        avatar5: {
          model: 'office-woman.glb',
          type: 'image',
          face: 'woman',
          position: '-3 0 11',
          rotation: '0 150 0'
        }
      }
    }
  },
  watch: {
    /*******************************************
     * 音楽が許可されたらBGMを再生してアニメーションを設定
     ******************************************/
    soundPermissionFlag(newBool) {
      if(newBool) {
        const bgmSound = document.getElementById('bgmSound');
        bgmSound.play();
        // アニメーションを設定
        for (let property in this.glbData.animation) {
          this.setAnimation(property);
        }
        this.setAvatarAnimation();
      }
    }
  },
  mounted() {
    // タイトルと説明、OGPを設定
    this.changePageContent();
    // 顔動画を再生
    for(const video in this.faceList){
      let videoElement = document.getElementById(video);
      videoElement.play();
    }

    /*******************************************
     * ワープゾーンの設定
     ******************************************/
    const positionChanged = (newPosition) => {
      for(let property in this.warpData) {
        let xWarpSpot = Math.ceil(this.warpData[property].position.x);
        let zWarpSpot = Math.ceil(this.warpData[property].position.z);
        // ワープゾーンに来たら確認ポップアップを起動
        if(xWarpSpot === newPosition.x && zWarpSpot === newPosition.z) {
          this.setConfirmWindow(property);
        }
      }
    };
    // ポジションを見張る処理
    window.AFRAME.registerComponent('position-listener', {
      tick() {
        const newValue = this.el.getAttribute('position');
        // 小数点を四捨五入
        const newPosition = {
          x: Math.ceil(newValue.x),
          z: Math.ceil(newValue.z)
        }
        // 文字列に置き換え
        const stringCoords = window.AFRAME.utils.coordinates.stringify({
          x: newPosition.x,
          y: 0,
          z: newPosition.z
        });
        // ポジションが変更しているかチェック
        if (this.lastValue !== stringCoords) {
          this.lastValue = stringCoords;
          // 変更がある場合はワープの位置をチェック
          positionChanged(newPosition);
        }
      }
    });
  },
  methods: {
    /*******************************************
     * 子から受け取った建物データを設定
     ******************************************/
    setGlbData(glbData) {
      this.glbData = glbData;
    },
    /*******************************************
     * 画面遷移のConfirm画面呼び出し
     ******************************************/
    setConfirmWindow(target) {
      this.targetUrl =  this.jsonData.linkData[target];
      this.confirmWindowFlag = true;
    },
    /*******************************************
     * パーツごとにアニメーションを設定する
     ******************************************/
    setAnimation(animationName) {
      let animationElement = document.getElementById(animationName);
      // エレベーター左
      if (animationElement.id === 'animation-ev-door-l') {
        animationElement.setAttribute('animation', 'property:object3D.position.x; to:1.1; dir:alternate; delay:1000; dur:2000; loop:false;');
      }
      // エレベーター右
      if (animationElement.id === 'animation-ev-door-r') {
        animationElement.setAttribute('animation', 'property:object3D.position.x; to:-1.1; dir:alternate; delay:1000; dur:2000; loop:false');
      }
      // ワープ
      if(animationElement.id === 'animation-warp') {
        // 原点にあるものは削除
        animationElement.remove();
        // glbのパスを取得
        const warpPath = this.glbData.animation['animation-warp'].path;
        // 各ポジションごとにパーツを作成
        const scene = document.getElementById('scene');
        for(let property in this.warpData) {
          let warp = document.createElement('a-entity');
          warp.setAttribute('id', property);
          warp.setAttribute('static-body', 'shape:none;');
          warp.setAttribute('gltf-model', warpPath);
          warp.setAttribute('position', this.warpData[property].position);
          warp.setAttribute('animation', 'property: rotation; from: 0 0 0; to: 0 360 0; dur: 800; easing: linear; loop: true');
          scene.appendChild(warp);
        }
      }
    },
    /*******************************************
     * アバターごとのメソッドを呼び出し
     ******************************************/
    setAvatarAnimation() {
      // アバターごとに呼び出すアニメーション設定を変更
      for(let avatar in this.avatarData) {
        let exception = [
          'info-avatar',
          'avatar3',
          'avatar4'
        ];
        // アニメーションを行わないアバターの場合は次のループへ
        if (exception.indexOf(avatar) !== -1) continue;
        const avatarElement = document.getElementById(avatar);
        let methodName = avatar + 'Animation';
        this[methodName](avatarElement, methodName);
      }
    },
    /*******************************************
     * アバターのアニメーションをリピート実行
     ******************************************/
    repeatAnimation(avatar, method, animationList) {
      // アニメーションを設定
      let model = document.getElementById(avatar);
      for (let animation in animationList.motion) {
        if (model.getAttribute(animation)) model.removeAttribute(animation);
        model.setAttribute(animation , animationList.motion[animation]);
      }
      // 設定時間後に再実行
      setTimeout( function() {
        this[method](model, method);
      }.bind(this), animationList.totalTime);
    },
    /*******************************************
     * アバターごとのアニメーションをリピート実行
     ******************************************/
    avatar1Animation(avatarElement, methodName) {
      this.repeatAnimation(avatarElement.id, methodName, {
        totalTime: 30000,
        motion: {
          animation__1: 'property:rotation; from:0 180 0; to:0 150 0; dur:3000; delay:1000;',
          animation__2: 'property:rotation; from:0 150 0; to:0 180 0; dur:3000; delay:13000;'
        }
      });
    },
    avatar2Animation(avatarElement, methodName) {
      this.repeatAnimation(avatarElement.id, methodName, {
        totalTime: 40000,
        motion: {
          animation__1_1: 'property:rotation; from:0 120 0; to:0 150 0; dur:1000; delay:800;',
          animation__1_2: 'property:position; from:-8.6 0 -10.5; to:-8.3 0 -12; dur:2000; delay:1000;',
          animation__2: 'property:rotation; from:0 150 0; to:0 120 0; dur:1000; delay:13000;',
          animation__3__1: 'property:rotation; from:0 120 0; to:0 30 0; dur:2000; delay:23000;',
          animation__3_2: 'property:position; from:-8.3 0 -12; to:-8.6 0 -10.5; dur:2000; delay:24800;',
          animation__4: 'property:rotation; from:0 30 0; to:0 120 0; dur:2000; delay:33000;'
        }
      });
    },
    avatar5Animation(avatarElement, methodName) {
      this.repeatAnimation(avatarElement.id, methodName, {
        totalTime: 30000,
        motion: {
          animation__1: 'property:rotation; from:0 150 0; to:0 30 0; dur:3000; delay:1000;',
          animation__2: 'property:rotation; from:0 30 0; to:0 150 0; dur:3000; delay:13000;'
        }
      });
    }
  }
}
</script>

<style scoped>
.contents {
  height: 100vh;
}
.vr-scene {
  height: 100%;
}

</style>
